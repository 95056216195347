import React from "react";
import { Helmet } from "react-helmet";

export const CareersJsonLd = () => {
  const mainjson = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    datePosted: "2020-09-17",
    validThrough: "2025-12-10",
    baseSalary: {
      "@type": "MonetaryAmount",
      minValue: "$",
      maxValue: "$",
      currency: "USD"
    },
    description:
      "Careers - Discover our UX Design Agency Jobs & Grow With Our Core Team!",
    educationRequirements: ["no requirements"],
    employmentType: "Full-Time - 100% Remote",
    incentiveCompensation:
      "Performance-based annual bonus plan, project-completion bonuses",
    industry: "Computer Software",
    hiringOrganization: "Adam Fard Studio",
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Neue Schönhauser Straße 3",
        addressLocality: "Berlin",
        addressRegion: "BE",
        postalCode: "10178"
      }
    },
    applicationContact: "contact@adamfard.com",
    occupationalCategory: [
      "Senior UX / UI Designer - FT",
      "Business Development Manager - FT",
      "UX Researcher - FT"
    ],
    qualifications: [
      "Experience with (selling) UX / UI Design services is a plus.",
      "Strong listening and presentation skills",
      "Strong phone/call presence",
      "Track record of over-achieving quota",
      "Proven inside sales experience",
      "Excellent verbal and written communications skills (English C2)"
    ],
    responsibilities: [
      "Conduct UX Audit & Heuristic Evaluation",
      "Define concepts according to tasks and business requirements",
      "Hi-fi Wireframes",
      "Source new sales opportunities through inbound lead follow-up and outbound calls and emails",
      "Understand customer needs and requirements",
      "Close sales and achieve quotas",
      "Perform effective online presentation (UX & Design services) to prospects",
      "Manage and grow existing accounts",
      "Map current user experience and journeys",
      "Design, manage and conduct qualitative and quantitative research-based upon long-term strategic needs as well as ad-hoc research requests",
      "Promote the value / ROI of user research in client's proposals and communications"
    ],
    salaryCurrency: "USD",
    skills: "3+ year of UX research hands-on experience",
    specialCommitments: ["English C2", "English C1"],
    title: "Join Our Team!",
    url: "https://adamfard.com/careers",
    image:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp"
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/careers/#Webpage",
    url: "https://adamfard.com/careers/",
    headline: "Careers",
    primaryImageOfPage: [
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp"
    ],
    lastReviewed: "2020/05/09",
    relatedLink: ["https://en.wikipedia.org/wiki/Usability_testing"],
    significantLink: "https://adamfard.com/contact-us",
    description:
      "Careers - Discover our UX Design Agency Jobs & Grow With Our Core Team! ",
    image:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
    sameAs: ["https://en.wikipedia.org/wiki/User_interface"],
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breadCrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "2",
    description:
      "Careers - Discover our UX Design Agency Jobs & Grow With Our Core Team!",
    disambiguatingDescription:
      "Discover our UX Design Agency Job Openings and Join Our Core Team!",
    mainEntityOfPage: "https://adamfard.com/careers/",
    image:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: { "@id": "https://adamfard.com/careers", name: "Careers" }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(mainjson)}</script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadCrumbJson)}
      </script>
    </Helmet>
  );
};
